import React, { forwardRef } from 'react';

const FileInput = forwardRef((props, ref) => (
  <input
    ref={ref}
    type="file"
    id="file-btn"
    accept=".mp3,.mp4"
    {...props}
  />
));

export default FileInput;