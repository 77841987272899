import React, { useEffect, useState } from 'react';
import 'pages/transcription/_transcription.scss';
import { useNavigate, useParams } from "react-router-dom";
import { routes, utils } from "common";
import { CustomAudioPlayer, Loader, Sidebar, TranscriptionSection } from "components";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "reduxStore/actions";
import { COMPLETED } from "common/constants";

function Transcription() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {transcriptionData, loading} = useSelector(state => state.general);
  const [currentTime, setCurrentTime] = useState(0);
  const [absoluteIndex, setAbsoluteIndex] = useState(0);

  const handleTranscriptionExport = () => {
    utils.generateTranscriptionPDF(transcriptionData);
  };

  useEffect(() => {
    if (id) {
      dispatch(generalExtraActions.audioTranscriptDetail(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(generalExtraActions.audioTranscriptList());
  }, []);


  return (<div className="transcription">
    {loading && <Loader/>}
    <Sidebar transcriptionId={id} absoluteIndex={absoluteIndex}/>
    <div className="content">
      {id ? <div className="transcription-detail">
        <TranscriptionSection
          transcriptionId={id} currentTime={currentTime} setAbsoluteIndex={setAbsoluteIndex}
        />
        <CustomAudioPlayer absoluteIndex={absoluteIndex} setCurrentTime={setCurrentTime}/>
        <div className="buttons">
          <a onClick={handleTranscriptionExport}
             aria-disabled={transcriptionData?.transcription_status !== COMPLETED}
          >Export Transcription</a>
          <a onClick={() => navigate(`${routes.SUMMARIES}/${id}`)}
             aria-disabled={transcriptionData?.transcription_status !== COMPLETED}
          >
            Summary
          </a>
        </div>
      </div> : <div className="no-transcription">
        Select the file from sidebar to view Transcription
      </div> }
    </div>
  </div>);
}

export default Transcription;
