import { fetchFile } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";

export const vtoa = {
  convert: async (videoFileData, targetAudioFormat) => {
    const contentType = 'audio/' + targetAudioFormat;
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    await ffmpeg.writeFile("input.mp4", await fetchFile(videoFileData));
    await ffmpeg.exec(["-i", "input.mp4", "output.mp3"]);
    const fileData = await ffmpeg.readFile('output.mp3');
    const blob = new Blob([fileData.buffer], {type: contentType});
    return {
      name: `${videoFileData.name.substring(0, videoFileData.name.lastIndexOf("."))}.${targetAudioFormat}`,
      blob: blob,
    }
  },
};