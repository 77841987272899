import React, { useEffect, useState } from 'react';
import "components/sidebar/_sidebar.scss";
import { IconPlay } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { SidebarFile, Speaker } from "components";
import { generalExtraActions } from "reduxStore/actions";
import { routes, utils } from "common";
import { useParams } from "react-router-dom";

function Sidebar({transcriptionId, absoluteIndex, forSummary = false}) {
  const {id} = useParams();
  const dispatch = useDispatch()
  const {
    audioTranscriptions, transcriptionData, transcriptionSummaries, summaryData
  } = useSelector(state => state.general);
  const [showFiles, setShowFiles] = useState(true);
  const [title, setTitle] = useState('');
  const [currentSpeaker, setCurrentSpeaker] = useState(null);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleTitleSave = () => {
    dispatch(generalExtraActions.audioTranscriptionUpdate(
      {
        id: transcriptionId,
        data: {title: title}
      }
    )).then((response) => {
      if (response?.type === "general/audioTranscriptionUpdate/fulfilled") {
        dispatch(generalExtraActions.audioTranscriptList());
      }
    });
  };

  useEffect(() => {
    setTitle(forSummary ? summaryData?.title || '' : transcriptionData?.title || '');
  }, [transcriptionData?.title, summaryData?.title]);

  useEffect(() => {
    const segment = utils.isObjectEmpty(transcriptionData) ? null : (
      transcriptionData?.transcription ? transcriptionData?.transcription[absoluteIndex] : null
    );
    setCurrentSpeaker(segment?.speaker);
  }, [absoluteIndex]);

  return (
    <div className="sidebar">
      <div className="files">
        <h2 id="accord" className={showFiles ? "opened" : ""} onClick={() => setShowFiles(!showFiles)}>
          All Files
          <span className="right-arrow"><img src={IconPlay} width="8px"/></span>
        </h2>
        <ul className={showFiles ? "opened" : ""}>
          {forSummary ? transcriptionSummaries?.map((transcriptionSummary) => {
            return <SidebarFile key={`${transcriptionSummary.id}-${transcriptionSummary.summary_status}`}
                                currentId={id}
                                id={transcriptionSummary.id}
                                link={`${routes.SUMMARIES}/${transcriptionSummary.id}`}
                                status={transcriptionSummary.summary_status}
                                title={transcriptionSummary.title}
                                timeInfo={transcriptionSummary.time_info} forSummary={forSummary}
            />
          }) : audioTranscriptions?.map((audioTranscription) => {
            return <SidebarFile key={`${audioTranscription.id}-${audioTranscription.transcription_status}`}
                                currentId={id}
                                id={audioTranscription.id}
                                link={`${routes.TRANSCRIPTIONS}/${audioTranscription.id}`}
                                status={audioTranscription.transcription_status}
                                title={audioTranscription.title}
                                timeInfo={audioTranscription.time_info} forSummary={forSummary}
            />
          })
          }
        </ul>
      </div>
      {!forSummary && transcriptionId && <>
        <div className="title-section">
          <h2 className="mt10">
            Title
            <span>Click to edit</span>
          </h2>
          <div className="flex">
            <span className="speaker">
              <input type="text" name='title' value={title} onChange={handleTitleChange}/>
              <button disabled={title === transcriptionData?.title || title.trim() === ""} onClick={handleTitleSave}>
                Save
              </button>
          </span>
          </div>
        </div>
        <div className="speakers">
          {transcriptionData?.speakers?.length > 0 && <>
            <h2 className="mt10">
              Speakers
              <span>Click to edit</span>
            </h2>
            <div className="flex">
              {
                transcriptionData?.speakers?.map((speaker) => {
                  return <Speaker
                    key={speaker.id}
                    transcriptionId={transcriptionId}
                    speaker={speaker}
                    isActive={currentSpeaker === speaker.id}
                  />
                })
              }
            </div>
          </>}
        </div>
      </>}
    </div>
  )
    ;
}

export default Sidebar;