import * as Yup from 'yup';
import { FILE_SIZE_LIMIT_MP4, FILE_SIZE_LIMIT_MPEG } from "common/constants";

export const schemas = {
  fileUpload: Yup.object({
    file: Yup.mixed()
      .required('A file is required')
      .test('fileFormat', 'Unsupported file type', value => {
        return value && (value.type === 'audio/mpeg' || value.type === 'video/mp4');
      })
      .test('fileSize', function (value) {
        if (value) {
          if (value.type === 'audio/mpeg' && value.size > FILE_SIZE_LIMIT_MPEG) {
            return this.createError({message: `File size cannot exceed 500 MB for audio`});
          } else if (value.type === 'video/mp4' && value.size > FILE_SIZE_LIMIT_MP4) {
            return this.createError({message: `File size cannot exceed 1 GB for video`});
          }
        }
        return true;
      }),
    fileType: Yup.string()
      .required('Please select a file type'),
  }),
};
