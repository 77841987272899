import React, { useEffect, useRef } from 'react';
import 'components/transcriptionSegment/_transcriptionSegment.scss';
import { useDispatch } from "react-redux";
import { generalActions } from "../../reduxStore/reducers/generalSlice";

function TranscriptionSegment({
                                segmentTime,
                                segmentText,
                                segmentSpeaker,
                                editMode,
                                isActive,
                                index,
                                speakers,
                                speakerMap,
                                handleCheckboxSelection,
                              }) {
  const dispatch = useDispatch();
  const segmentRef = useRef();

  const handleTextChange = (event) => {
    dispatch(generalActions.updateTranscriptionSegmentText({index, updatedText: event.target.value}));
  };

  const handleSpeakerChange = (event) => {
    dispatch(generalActions.updateTranscriptionSegmentSpeaker(
      {index, speaker: parseInt(event.target.options[event.target.selectedIndex].value)}
    ));
  };

  useEffect(() => {
    if (isActive) {
      segmentRef.current?.scrollIntoView({behavior: "smooth", block: "start"})
    }
  }, [isActive]);

  return (
    <li ref={segmentRef} className={isActive ? "active" : ""}>
      {editMode &&
        <input id={`selector-${index}`} className="selector" type="checkbox" onChange={handleCheckboxSelection}/>
      }
      <label className="time" htmlFor={`selector-${index}`}>{segmentTime}</label>
      {
        editMode ? <select className="speakers" onChange={handleSpeakerChange}>
            {
              speakers.map((speaker, index) => {
                return <option key={index} value={speaker.id}
                               selected={speaker.id === segmentSpeaker}>{speaker.name}</option>
              })
            }
          </select> :
          <span className="speaker">({speakerMap[segmentSpeaker]?.name})</span>
      }
      {
        editMode ?
          <textarea className="transcription" onChange={handleTextChange} defaultValue={segmentText}/>
          :
          <span className="transcription">{segmentText}</span>
      }
    </li>
  );
}

export default TranscriptionSegment;