import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'api';

const generalExtraActions = {
  audioTranscriptList: createAsyncThunk('general/audioTranscriptList', api.audioTranscriptList),
  audioTranscriptDetail: createAsyncThunk('general/audioTranscriptDetail', api.audioTranscriptDetail),
  audioTranscriptionUpdate: createAsyncThunk('general/audioTranscriptionUpdate', api.audioTranscriptionUpdate),
  transcriptionSummaryList: createAsyncThunk('general/transcriptionSummaryList', api.transcriptionSummaryList),
  transcriptionSummaryDetail: createAsyncThunk('general/transcriptionSummaryDetail', api.transcriptionSummaryDetail),
  transcriptionSummaryCreate: createAsyncThunk('general/transcriptionSummaryCreate', api.transcriptionSummaryCreate),
  audioRetranscribe: createAsyncThunk('general/audioRetranscribe', api.audioRetranscribe)
};

export default generalExtraActions;
