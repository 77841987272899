import React from 'react';
import { IconHero } from "assets/icons";
import "pages/landing/_landing.scss";
import { Link } from "react-router-dom";
import { routes } from "common";
import { useSelector } from "react-redux";
import { Loader } from "components";

function Landing() {
  const {user, isUserLoggedIn} = useSelector(state => state.auth);
  const ZOOM_APP_LINK = 'https://zoom.us/oauth/authorize?client_id=I49NstvzSZDdWQZjKkCmw&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fconnect-zoom%2F';

  return (
    <div className="landing">
      <div className="custom-container flex">
        <div className="texts">
          <h1>Speech to Text <span>Transcribe your speech in two steps</span></h1>
          <p>
            Use Bilingual audio and video and transcribe them into one language. With the transcription, be able to
            generate summary and analysis
          </p>
          {isUserLoggedIn && <div className="upload-section">
            <Link className="upload-btn" to={routes.UPLOAD}>Upload File</Link>
          </div>}
          {/*{!user?.is_zoom_connect && <div className="upload-section">*/}
          {/*  <Link className="upload-btn" to={ZOOM_APP_LINK}>Link Zoom Account</Link>*/}
          {/*</div>}*/}
        </div>
        <div className="hero-img">
          <img src={IconHero} alt="hero"/>
        </div>
      </div>
    </div>
  );
}

export default Landing;
