import React from 'react';
import { IconPlay } from "../../assets/icons";

function AudioSegment({audioSegmentUrl, time, speaker, text}) {
    return (
        <li>
            <img src={IconPlay} width="8"/>
            <span className="time">01:50 - 02:25</span>
            <span className="speaker-name">(speaker1)</span>
            <span className="text">It is a long a established fact that a reader will be </span>
        </li>
    );
}

export default AudioSegment;