import React, { useEffect, useState } from 'react';
import 'pages/upload/_upload.scss';
import { useFormik } from "formik";
import { routes, schemas, utils, vtoa } from "common";
import {
  EXTRACT_PROGRESS,
  FINAL_STATES,
  IN_PROGRESS_FETCH_INTERVAL,
  PROGRESS_STOP_AT,
  STATE_CODES,
  STATE_FETCH_INTERVAL,
  SUMMARY_PROGRESS,
  TRANSCRIPTION_PROGRESS,
  UPLOAD_PROGRESS
} from "common/constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleError } from "api/helpers";
import { getConfig } from "api/utils";
import { api, endpoints } from "api";
import UploadSection1 from "./UploadSection1";
import UploadSection2 from "./UploadSection2";

function Upload() {
  const navigate = useNavigate();
  const [section, setSection] = useState(0);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [state, setState] = useState(STATE_CODES.ANALYZING);
  const [audioTranscription, setAudioTranscription] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(0);

  const fetchRemainingTime = async () => {
    try {
      const data = state <= STATE_CODES.TRANSCRIPTION_COMPLETED ? await api.transcriptionRemainingTime(audioTranscription.id) : await api.summaryRemainingTime(audioTranscription.id);
      setRemainingTime(Math.round(data.time_info.remaining_time));
      setEstimatedTime(Math.round(data.time_info.estimated_time));
    } catch (error) {
      console.log(error)
    }
  };

  const updateProgress = (newProgress) => {
    if (newProgress > progress && newProgress < PROGRESS_STOP_AT) {
      setProgress(newProgress);
    }
  };


  const handleAudioTranscriptCreate = async (url, data, withToken) => {
    try {
      const config = getConfig(withToken);
      config.onUploadProgress = data => {
        updateProgress(utils.mapProgress(data.progress * 100, UPLOAD_PROGRESS.MIN, UPLOAD_PROGRESS.MAX));
      }
      return (await axios.post(url, data, config)).data;
    } catch (error) {
      return await handleError(error, handleAudioTranscriptCreate, url, data, withToken);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.currentTarget.files[0];
    if (selectedFile) {
      formik.setFieldValue('file', selectedFile);
      setFileName(selectedFile.name);
      setSection(1);
    }
  };


  const extractAudioFromVideo = async (file) => {
    const interval = setInterval(() => {
      setProgress(progress => utils.mapProgress(progress * 10 + 10, EXTRACT_PROGRESS.MIN, EXTRACT_PROGRESS.MAX
      ));
    }, (utils.getEstimateConversionTime(file.size) / EXTRACT_PROGRESS.MAX - EXTRACT_PROGRESS.MIN) * 1000);
    const audioData = await vtoa.convert(file, 'mp3');
    const audioFile = new File([audioData.blob], audioData.name, {type: "audio/mpeg"});
    clearInterval(interval);
    return audioFile
  }

  const createFormData = async (values) => {
    const formData = new FormData();
    formData.append('file_type', values.fileType);
    if (values.file.type === 'video/mp4') {
      setState(STATE_CODES.EXTRACTING_AUDIO);
      const audioFile = await extractAudioFromVideo(values.file)
      formData.append('input_file', audioFile);
    } else {
      formData.append('input_file', values.file);
    }
    return formData;
  }

  const formik = useFormik({
    initialValues: {
      file: null, fileType: '',
    }, validationSchema: schemas.fileUpload, onSubmit: async (values) => {
      setLoading(true);
      setShowProgress(true);
      const formData = await createFormData(values);
      setState(STATE_CODES.UPLOADING_FILE);
      try {
        const data = await handleAudioTranscriptCreate(endpoints.AUDIO_TRANSCRIPTIONS, formData, true);
        setAudioTranscription(data)
      } catch (error) {
        setState(STATE_CODES.TRANSCRIPTION_FAILED);
        console.error(error);
      }
    },
  });


  useEffect(() => {
    if (!audioTranscription) return;
    const interval = setInterval(async () => {
      try {
        const data = await api.overallAudioTranscriptionState(audioTranscription.id)
        setState(data.state);
        if (data.state <= STATE_CODES.TRANSCRIPTION_COMPLETED) {
          updateProgress(utils.mapProgress(data.progress, TRANSCRIPTION_PROGRESS.MIN, TRANSCRIPTION_PROGRESS.MAX));
        } else {
          updateProgress(utils.mapProgress(data.progress, SUMMARY_PROGRESS.MIN, SUMMARY_PROGRESS.MAX));
        }

      } catch (error) {
        console.log(error)
      }
    }, STATE_FETCH_INTERVAL * 1000);
    return () => clearInterval(interval);
  }, [audioTranscription]);


  useEffect(() => {
    if (FINAL_STATES.includes(state)) {
      if (audioTranscription) {
        navigate(`${routes.TRANSCRIPTIONS}/${audioTranscription.id}`);
      } else {
        navigate(routes.TRANSCRIPTIONS)
      }
    }
    if (state === STATE_CODES.TRANSCRIPTION_IN_PROGRESS || state === STATE_CODES.SUMMARY_IN_PROGRESS) {
      fetchRemainingTime();
    }
  }, [state]);


  useEffect(() => {
    if (state < STATE_CODES.TRANSCRIPTION_IN_PROGRESS) return;
    const interval = setInterval(() => {
      setRemainingTime(remainingTime => Math.round(remainingTime - 1));
    }, 1000);
    return () => clearInterval(interval);
  }, [state]);

  useEffect(() => {
    if (state < STATE_CODES.TRANSCRIPTION_IN_PROGRESS) return;
    if (estimatedTime && remainingTime) {
      let newProgress = Math.round(100 - ((remainingTime / estimatedTime) * 100));
      if (state === STATE_CODES.TRANSCRIPTION_IN_PROGRESS) {
        newProgress = utils.mapProgress(newProgress, TRANSCRIPTION_PROGRESS.MIN, TRANSCRIPTION_PROGRESS.MAX)
      }
      if (state === STATE_CODES.SUMMARY_IN_PROGRESS) {
        newProgress = utils.mapProgress(newProgress, SUMMARY_PROGRESS.MIN, SUMMARY_PROGRESS.MAX)
      }
      updateProgress(newProgress)
      if (Math.round(estimatedTime - remainingTime) % IN_PROGRESS_FETCH_INTERVAL === 0) {
        fetchRemainingTime();
      }
    }

  }, [remainingTime, estimatedTime])

  return (
    <div className="upload">
      <h1 className="heading">
        Transcribe your video or audio to text
      </h1>
      {
        section === 0 &&
        <UploadSection1
          formik={formik}
          setFileName={setFileName}
          setSection={setSection}
          handleFileChange={handleFileChange}
        />
      }
      {
        section === 1 &&
        <UploadSection2
          formik={formik}
          uploadProgress={progress}
          fileName={fileName}
          loading={loading}
          showProgress={showProgress}
          state={state}
          handleFileChange={handleFileChange}
        />
      }
    </div>
  );
}

export default Upload;
