import React from 'react';
import 'components/footer/_footer.scss';

function Footer(props) {
    return (
        <footer className={'footer'}>
        </footer>
    );
}

export default Footer;
