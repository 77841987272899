import React, { useRef } from 'react';
import "components/customAudioPlayer/_customAudioPlayer.scss";
import { AUDIO_JUMP_TIME, AUDIO_TIME_FORMAT } from "../../common/constants";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  PauseCircleFill,
  PlayCircleFill,
  SkipEndFill,
  SkipStartFill,
  VolumeMuteFill,
  VolumeUpFill
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";

function CustomAudioPlayer({absoluteIndex, setCurrentTime}) {
  const audioPlayer = useRef(null);
  const {transcriptionData} = useSelector(state => state.general);
  const iconStyles = {
    size: 40,
    color: "#3c3c3c"
  };

  const goToSegment = (jumpIndex) => {
    const transcriptionLength = transcriptionData?.transcription?.length || 0;
    if (jumpIndex >= 0 && jumpIndex < transcriptionLength) {
      const currentEndTime = transcriptionData?.transcription[absoluteIndex].end_time;
      const nextStartTime = transcriptionData?.transcription[jumpIndex].start_time;
      const updatedTime = currentEndTime === nextStartTime ? nextStartTime + 1 : nextStartTime;
      setCurrentTime(updatedTime);
      if (audioPlayer.current) {
        audioPlayer.current.audio.current.currentTime = updatedTime;
      }

    }
  };

  return (
    <div className="audio-player">
      <AudioPlayer
        ref={audioPlayer}
        src={transcriptionData?.audio_url}
        progressJumpStep={AUDIO_JUMP_TIME}
        timeFormat={AUDIO_TIME_FORMAT}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        showSkipControls={true}
        showJumpControls={false}
        hasDefaultKeyBindings={false}
        onClickNext={() => goToSegment(absoluteIndex + 1)}
        onClickPrevious={() => goToSegment(absoluteIndex - 1)}
        customIcons={{
          play: <PlayCircleFill {...iconStyles} />,
          pause: <PauseCircleFill {...iconStyles} />,
          next: <SkipEndFill {...iconStyles} />,
          previous: <SkipStartFill {...iconStyles} />,
          volume: <VolumeUpFill color={iconStyles.color}/>,
          volumeMute: <VolumeMuteFill color={iconStyles.color}/>,
        }}
        onListen={(event) => {
          setCurrentTime(event.srcElement.currentTime);
        }}
      />
    </div>
  );
}

export default CustomAudioPlayer;