import React, { useRef } from 'react';
import { FileInput, ProgressBar } from "components";
import { COMPLETED_STATE_MESSAGES, FILE_TYPES, STATE_CODES, STATE_MESSAGES } from "common/constants";
import "pages/upload/_upload.scss";

function UploadSection2({formik, uploadProgress, fileName, loading, showProgress, state, handleFileChange}) {
  const state_codes = Object.values(STATE_CODES).reverse();
  const fileInputRef = useRef(null);

  const handleDivClick = (event) => {
    if (event.target !== fileInputRef.current && event.target.tagName !== 'LABEL') {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="section section-2">
      <form className="upload-form" onSubmit={formik.handleSubmit}>
        <div>
          <div className="file-field" onClick={handleDivClick}>
            <span className="file-name">{fileName}</span>
            <div className="file-handle">
              <FileInput
                ref={fileInputRef}
                onChange={handleFileChange}
                disabled={loading}
              />
              <label htmlFor="file-btn">Change file</label>
            </div>
          </div>
          {formik.errors.file ? (
            <div className="error">{formik.errors.file}</div>
          ) : null}
        </div>
        <div className="file-types">
          <p className='heading'>Select meeting type for transcription</p>
          {Object.entries(FILE_TYPES).map(([key, value], index) => {
            const inputId = `fileType-${key}`;
            return <span key={index}>
                                    <input
                                      id={inputId}
                                      type="radio"
                                      name="fileType"
                                      value={key}
                                      onChange={formik.handleChange}
                                      disabled={loading}
                                    />
                                    <label htmlFor={inputId}>{value}</label>
                                </span>
          })}
          {formik.errors.fileType && formik.touched.fileType ? (
            <div className="error">{formik.errors.fileType}</div>
          ) : null}
        </div>
        <div>
          {showProgress && <ProgressBar progress={uploadProgress}/>}
          {showProgress && state_codes.map((state_code, index) => {
            const isCurrentStep = state_code === state;
            return state_code <= state && <li className={`step-msg ${isCurrentStep ? "current-msg" : ""}`}>
              {isCurrentStep ? STATE_MESSAGES[state_code] : COMPLETED_STATE_MESSAGES[state_code]}{" "}
              {isCurrentStep && state_code <= STATE_CODES.UPLOADING_FILE && "(Don't close the tab) "}
            </li>
          })}
        </div>
        <input type="submit" value="Textify it" className="btn-submit" disabled={loading}/>
      </form>
    </div>
  );
}

export default UploadSection2;